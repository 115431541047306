import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { ChangeRequestApproversGroupDto } from '../../../models/changeRequests/ChangeRequestApproversGroupDto';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';
import { ChangeRequestResponse } from '../../../models/changeRequests/ChangeRequestResponse';
import { UserDto } from '../../../models/userroles/users/UserDto';
import { ChangeRequestsService } from '../../../repository/ChangeRequestsService';
import AproversAndDeclinersTable from '../ChangeRequestDetailsPage/AproversAndDeclinersTable';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import UpdateCountryRiskChangeRequestDetails from './UpdateCountryRiskChangeRequestDetails';
import UpdateCountryRiskExcelChangeRequestDetails from './UpdateCountryRiskExcelChangeRequestDetails';
import UpdatePersonChangeRequestDetails from './UpdatePersonChangeRequestDetails';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import UpdateQuestionnaireChangeRequestDetails from './UpdateQuestionnaireChangeRequestDetails';
import { FullPageLoadingCircle } from '../../../components/FullPageLoadingCircle/FullpageLoadingCircle';

const ChangeRequestDetails = () => {
  const params = useParams();
  const [changeRequestResponse, setChangeRequestResponse] = useState<ChangeRequestResponse>();
  const [changeRequestDetails, setChangeRequestDetails] = useState<ChangeRequestDetailsResponse>();
  const { t } = useTranslation();
  const [approvers, setApprovers] = useState<UserDto[]>([]);
  const [decliners, setDecliners] = useState<UserDto[]>([]);
  const [getChangedFields, setChangedFields] = useState();
  const [showOpenApproveConfirmation, setOpenApproveConfirmation] = useState(false);
  const [showOpenDeclineConfirmation, setOpenDeclineConfirmation] = useState(false);
  const [changeRequestApproversForRole, setChangeRequestApproversForRole] = useState<ChangeRequestApproversGroupDto[]>([]);
  const [changeRequestDeclinersForRole, setChangeRequestDeclinersForRole] = useState<ChangeRequestApproversGroupDto[]>([]);
  const [requiredRequestApprovers, setRequiredRequestApprovers] = useState<ChangeRequestApproversGroupDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (params.id) {
      getChangeRequest();
      getChangeRequestDetails();
    }
  }, [params.id]);

  const getChangeRequest = () => {
    ChangeRequestsService.getChangeRequestById(params.id!).then((res) => {
      setChangeRequestResponse(res);
      const approversList: UserDto[] = res.approvers.map((approver: any) => approver.userDto);
      const declinersList: UserDto[] = res.decliners.map((decliner: any) => decliner.userDto);
      setChangedFields(JSON.parse(res?.changedFields || '{}'));
      setApprovers(approversList);
      setDecliners(declinersList);
      setChangeRequestApproversForRole(res.changeRequestApproversForRole || []);
      setChangeRequestDeclinersForRole(res.changeRequestDeclinersForRole || []);
      setRequiredRequestApprovers(res.requiredRequestApprovers || []);
    });
  };

  const getChangeRequestDetails = () => {
    ChangeRequestsService.getChangeRequestDetails(params.id!).then((res) => {
      setChangeRequestDetails(res);
    });
  };

  const handleApproveChangeRequest = async () => {
    try {
      setLoading(true);
      if (params.id) {
        await ChangeRequestsService.checkAndApproveChangeRequests(params.id, true).then(() => {
          SwalAlert.successfulAlert(t('SUCCESS'), t('CHANGE_REQUEST_APPROVED'));
          setOpenApproveConfirmation(false);

        }).catch(() => {
          SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
          setOpenApproveConfirmation(false);
        });
        getChangeRequest();
      }
    } catch (error) {
      SwalAlert.errorAlert(t('ERROR_APPROVING_CHANGE_REQUEST'), error);
    }finally {
      setLoading(false);
    }
  };

  const handleDeclineChangeRequest = async () => {
    setLoading(true);
    try {
      if (params.id) {
        await ChangeRequestsService.checkAndApproveChangeRequests(params.id, false).then(() => {
          SwalAlert.successfulAlert(t('SUCCESS'), t('CHANGE_REQUEST_DECLINED'));
          setOpenDeclineConfirmation(false);
        }).catch(() => {
          SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
          setOpenDeclineConfirmation(false);
        });
        getChangeRequest();
      }
    } catch (error) {
      SwalAlert.errorAlert(t('ERROR_DECLINING_CHANGE_REQUEST'), error);
    }finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={4} padding={2}>
        {changeRequestResponse && (
          <>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography variant='body1'>
                {t('CHANGE_REQUEST_TYPE')}: {changeRequestResponse.changeRequestType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography variant='body1'>
                {t('CREATED_BY')}: {`${changeRequestResponse.createdBy.firstName} ${changeRequestResponse.createdBy.lastName}`}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} lg={4} xl={4}>
              {requiredRequestApprovers.map(requiredApprover => {
                const approvers = changeRequestApproversForRole.find(approver => approver.role === requiredApprover.role)?.approversNumber || 0;
                const decliners = changeRequestDeclinersForRole.find(decliner => decliner.role === requiredApprover.role)?.approversNumber || 0;
                return (
                  <Typography key={requiredApprover.role} variant='body1'>
                    {`${requiredApprover.role}: Approved: ${approvers}, Declined: ${decliners}, Required for role: ${requiredApprover.approversNumber}`}
                  </Typography>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={12} lg={2} xl={2}>
              <Typography variant='body1'>
                {t('APPROVED_BY')}: <b>{changeRequestResponse.approvedBy}</b>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} lg={2} xl={2}>
              <Typography variant='body1'>
                {t('REJECTED_BY')}: <b>{changeRequestResponse.rejectedBy}</b>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} lg={2} xl={2}>
              <Typography variant='body1'>
                {t('NOT_ANSWERED')}: <b>{changeRequestResponse.notAnswered}</b>
              </Typography>
            </Grid>

            <Grid container spacing={1} justifyContent='flex-start' sx={{
              pl: 2,
              marginTop: 2
            }}>
              {!changeRequestResponse.hasVoted && (
                <>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setOpenApproveConfirmation(true)}
                    >
                      Approve Changes
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() => setOpenDeclineConfirmation(true)}
                    >
                      Decline Changes
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {changeRequestResponse.changeRequestType === 'Update country risk score' && (
              <UpdateCountryRiskChangeRequestDetails
                changeRequestDetails={changeRequestDetails}
                changedFields={getChangedFields}
              />
            )}

            {changeRequestResponse.changeRequestType === 'Update person' && (
              <UpdatePersonChangeRequestDetails
                changeRequestDetails={changeRequestDetails}
                changedFields={getChangedFields}
              />
            )}

            {changeRequestResponse.changeRequestType === 'Update questionnaire' && (
              <UpdateQuestionnaireChangeRequestDetails
                changeRequestDetails={changeRequestDetails}
                changedFields={getChangedFields}
              />
            )}

            {changeRequestResponse.changeRequestType === 'Update country risk score with excel' && (
              <UpdateCountryRiskExcelChangeRequestDetails
                changeRequestDetails={changeRequestDetails}
                changedFields={getChangedFields}
              />
            )}


            <Grid item xs={12} sm={6}>
              <h3>{t('Approvers')}</h3>
              <AproversAndDeclinersTable users={approvers}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>{t('Decliners')}</h3>
              <AproversAndDeclinersTable users={decliners}/>
            </Grid>
          </>
        )}
      </Grid>
        <ConfirmationDialog
          open={showOpenApproveConfirmation}
          handleClose={() => setOpenApproveConfirmation(false)}
          handleConfirm={handleApproveChangeRequest}
          title='Confirm Approve Change Request'
          content='Are you sure you want to Approve this request?'
          actionButtonName='Confirm'
        />
        <ConfirmationDialog
          open={showOpenDeclineConfirmation}
          handleClose={() => setOpenDeclineConfirmation(false)}
          handleConfirm={handleDeclineChangeRequest}
          title='Confirm Decline Change Request'
          content='Are you sure you want to Reject this request?'
          actionButtonName='Confirm'
        />
      <FullPageLoadingCircle loading={loading}/>
    </>
  );
};

export default ChangeRequestDetails;