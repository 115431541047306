import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FullPageLoadingCircle } from '../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../../models/aml/FileDTO';
import { ClientDto } from '../../../models/clients/ClientDto';
import { ClientType } from '../../../models/clients/constants/ClientType';
import { AMLProcedure } from '../../../models/procedure/AMLProcedure';
import KYCProcedure from '../../../models/procedure/KYCProcedure';
import { ProcedureTokenResponse } from '../../../models/procedure/ProcedureTokenResponse';
import { ProcedureTypeShortName } from '../../../models/procedure/ProcedureTypeShortName';
import { ShareholderDto } from '../../../models/procedure/ShareholderDto';
import { ClientService } from '../../../repository/ClientService';
import { ClientTokenService } from '../../../repository/ClientTokenService';
import { ProcedureService } from '../../../repository/ProcedureService';
import ShareholderService from '../../../repository/ShareholderService';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import ProcedureLink from '../../InviteClients/AmlProcedureLink/ProcedureLink';
import { GbgFilesType } from '../enums/GbgFilesType';
import './active-procedure.scss';
import AMLProcedureView from './AMLProcedureView';
import ClientProcedureProps from './ClientActiveProcedureProps';
import KYCProcedureView from './KYCProcedureView';
import PDFViewer from './PDFViewer';

const ClientsActiveProcedure = (props: ClientProcedureProps) => {
  const [activeKYCProcedure, setActiveKYCProcedure] = useState<KYCProcedure>();
  const [activeAMLProcedure, setActiveAMLProcedure] = useState<AMLProcedure>();
  const [reload, setReload] = useState<boolean>(false);
  const [fileForGbgProcedure, setFileFormGbgProcedure] = useState<FileDTO[]>();
  const [fileForReview, setFileForReview] = useState<FileDTO>();
  const [amlLinkExpired, setAmlLinkExpired] = useState<boolean>(true);
  const [kycLinkExpired, setKycLinkExpired] = useState<boolean>(true);
  const [AMLProcedureTokenDetails, setAMLProcedureTokenDetails] = useState<ProcedureTokenResponse>();
  const [KYCProcedureTokenDetails, setKYCProcedureTokenDetails] = useState<ProcedureTokenResponse>();
  const [currentClient, setCurrentClient] = useState<ClientDto>();
  const [shareholders, setShareholders] = useState<ShareholderDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const base64Text = 'data:image/*;base64';
  const bottomRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleInitialNewAmlProcedure = (procedureType: ProcedureTypeShortName) => {
    setLoading(true);
    ProcedureService.reInitializeProcedure(currentClient!, procedureType).then((data) => {
      getDetailsForAMLToken(currentClient?.id!);
      // ProcedureService.sendEmailToClientForProcedureToken(currentClient?.email!, ProcedureTypeShortName.AML);
      setLoading(false);
      SwalAlert.successfulAlert(t('SUCCESSFULLY_INITIATED_AML_LINK'), t('SUCCESSFULLY_INITIATED_AML_LINK_MESSAGE'));
    }).catch((e) => {
      setLoading(false);
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
  };
  const handleInitialNewKycProcedure = (procedureType: ProcedureTypeShortName) => {
    setLoading(true);
    ProcedureService.reInitializeProcedure(currentClient!, procedureType).then((data) => {
      getDetailsForKYCToken(currentClient?.id!);
      setLoading(false);
      SwalAlert.successfulAlert(t('SUCCESSFULLY_INITIATED_KYC_LINK'), t('SUCCESSFULLY_INITIATED_KYC_LINK_MESSAGE'));
    }).catch((e) => {
      setLoading(false);
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
  };

  const getDetailsForAMLToken = (id: number) => {
    ProcedureService.getDetailsForAMLToken(id).then(async (data: ProcedureTokenResponse) => {
      setAMLProcedureTokenDetails(data);
      if (data.token) {
        setAmlLinkExpired(await isLinkExpired(data.token));
      }
    }).catch((error) => {
    });
  };
  const getDetailsForKYCToken = (id: number) => {
    ProcedureService.getDetailsForKYCToken(id).then(async (data: ProcedureTokenResponse) => {
      setKYCProcedureTokenDetails(data);
      if (data.token) {
        setKycLinkExpired(await isLinkExpired(data.token));
      }
    }).catch((error) => {
    });
  };

  const isLinkExpired = (link: string) => {
    return ClientTokenService.isTokenExpired(link).then(res => {
      return res;
    });
  };

  useEffect(() => {
    getActiveKYCProcedure();
    getActiveAMLProcedure();
  }, [props.clientId]);

  useEffect(() => {
    if (fileForReview) {
      if (window.innerWidth >= 1200) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [fileForReview]);

  useEffect(() => {
    {
      reload &&
      getActiveKYCProcedure();
      getActiveAMLProcedure();
    }
  }, [reload]);

  const getActiveKYCProcedure = () => {
    ProcedureService.getActiveKYCProcedureForClient(props.clientId)
      .then((data: KYCProcedure) => {
        getGbgDocuments(data.procedureId);
        setActiveKYCProcedure(data);
        ClientService.getClientById(data.clientId.toString()).then((res) => {
          setCurrentClient(res);
          getDetailsForAMLToken(res?.id!);
          getDetailsForKYCToken(res?.id!);
        });

        ShareholderService.allShareholdersByKycProcedure(data.procedureId).then((res) => {
          setShareholders(res);
        });

      });
  };

  const getGbgDocuments = (id: number) => {
    ProcedureService.getGbgDocuments(id).then((data: FileDTO[]) => setFileFormGbgProcedure(data));
  };

  const getActiveAMLProcedure = () => {
    ProcedureService.getActiveAMLProcedureForClient(props.clientId).then((data: AMLProcedure) => setActiveAMLProcedure(data));
  };

  const handleChangeSelectedFileForEdit = (file: FileDTO) => {
    setFileForReview(file);
  };

  return (
    <Grid container height={'100%'}>
      <FullPageLoadingCircle loading={loading}/>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Grid container marginY={2}>
          <Grid container>
            <Grid item xs={10} sm={12} md={12} lg={12} xl={12} marginTop={2} textAlign={'center'}>
              <img id='output' className='procedure-user-selfie' width='200px' height={'200px'}
                   src={`${base64Text},${fileForGbgProcedure?.filter((file: FileDTO) => file.name.includes(GbgFilesType.SELFIE_PHOTO))[0]?.base64Content}`}/>
            </Grid>
            {fileForGbgProcedure?.filter((file) => !file.name.includes(GbgFilesType.SELFIE_PHOTO) && !file.name.includes(GbgFilesType.FACE_PORTRAIT))
              .map((file, index) => {
                return (
                  <Grid key={index} item xs={10} sm={12} md={12} lg={6} xl={6} marginTop={2} textAlign={'center'}>
                    <img id='output' className='procedure-user-card' height={'200px'}
                         src={`${base64Text},${file?.base64Content}`}/>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item xs={12} marginTop={2}>
            {activeKYCProcedure ?
              (<Accordion defaultExpanded={true} className={'here'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Typography variant='h5'><b>{t('KYC_PROCEDURE_DETAILS')}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {activeKYCProcedure && <KYCProcedureView kycProcedure={activeKYCProcedure} reload={setReload}
                                                           handleChangeSelectedFileForEdit={handleChangeSelectedFileForEdit}
                                                           shareholders={shareholders}/>}
                </AccordionDetails>
              </Accordion>) :
              (<Grid container className={'empty-procedure'}>
                <Grid item xs={10} sm={8} md={6} lg={6} xl={6} marginY={1} textAlign={'center'}>
                  <i>{t('NO_ACTIVE_KYC')}</i>
                </Grid>
              </Grid>)}
          </Grid>
        </Grid>
        {currentClient?.clientType === ClientType.PRIMARY &&
          <Grid container marginY={2}>
            <Grid item xs={12}>
              {activeAMLProcedure ?
                (<Accordion defaultExpanded={true} className={'here'}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    <Typography variant='h5'><b>{t('AML_PROCEDURE_DETAILS')}</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {activeAMLProcedure && <AMLProcedureView amlProcedure={activeAMLProcedure} reload={setReload}
                                                             handleChangeSelectedFileForEdit={handleChangeSelectedFileForEdit}/>}
                  </AccordionDetails>
                </Accordion>) :
                (<Grid container className={'empty-procedure'}>
                    <Grid item xs={12} marginY={1} textAlign={'center'}>
                      <i>{t('NO_ACTIVE_AML')}</i>
                    </Grid>
                    <Grid item xs={12}>
                      <ProcedureLink selectedClient={activeKYCProcedure?.client!} procedureType={ProcedureTypeShortName.AML}
                                     tokenDetails={AMLProcedureTokenDetails!}
                                     isLinkExpired={amlLinkExpired} initiateProcedure={handleInitialNewAmlProcedure} hasKycProcedure={true}/>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        }
        {currentClient?.clientType === ClientType.SECONDARY &&
          <Grid container className={'empty-procedure'}>
            <Grid item xs={12} marginY={1} textAlign={'center'}>
              <i>{t('INITIATE_NEW_PROCEDURE')}</i>
            </Grid>
            <Grid item xs={12}>
              <ProcedureLink selectedClient={activeKYCProcedure?.client!} procedureType={ProcedureTypeShortName.KYC}
                             tokenDetails={KYCProcedureTokenDetails!}
                             isLinkExpired={kycLinkExpired} initiateProcedure={handleInitialNewKycProcedure} showInitiateProcedureButton={false}/>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} marginY={2}>
        <Box sx={{ border: '1px solid whitesmoke', height: '100%', padding: '10px' }}>
          {fileForReview ?
            (<Box ref={bottomRef}>
              {fileForReview.mimeType.includes('application/pdf') ?
                (<>
                  <PDFViewer fileForReview={fileForReview.base64Content}/>
                  <Box ref={bottomRef}> </Box>
                </>)
                :
                (
                  <>
                    <img id='output' className='procedure-image-file'
                         src={`${base64Text},${fileForReview.base64Content}`}/>
                    <Box ref={bottomRef}> </Box>
                  </>)
              }
            </Box>) : (
              <Box textAlign={'center'} color={'secondary'} ref={bottomRef}>
                <i>{t('NO_SELECTED_FILE')}</i>
              </Box>
            )
          }
        </Box>
      </Grid>
    </Grid>
  );
};
export default ClientsActiveProcedure;
