import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';
import AMLQuestionGroup from '../../../models/clientQuestionnaires/AMLQuestionGroup';
import ClientQuestionnairesDTO from '../../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import QuestionsType from '../../../models/clientQuestionnaires/QuestionsType';
import QuestionsService from '../../../repository/QuestionsService';
import AMLQuestion from '../../ClientQuestionaires/AMLQuestionnaires/AMLQuestion';

const UpdateQuestionnaireChangeRequestDetails = ({
  changeRequestDetails,
  changedFields
}: { changeRequestDetails: ChangeRequestDetailsResponse | undefined; changedFields: any }) => {
  const { t } = useTranslation();
  const [activeQuestionGroup, setActiveQuestionGroup] = useState<AMLQuestionGroup>();
  const [allActiveQuestionsList, setAllActiveQuestionsList] = useState<ClientQuestionnairesDTO[]>([]);
  const [allQuestionsList, setAllQuestionsList] = useState<ClientQuestionnairesDTO[]>([]);
  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>([]);
  const groupType = changedFields.type;

  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType().then((data) => {
      setQuestionTypeList(data.data);
    }).catch(() => {
    });
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)'
  };

  const getActiveAMLQuestionsByGroup = () => {
    QuestionsService.getActiveAMLQuestionsByGroup(groupType).then(
      (data) => {
        setActiveQuestionGroup(data.data);
      }
    ).catch(() => {
    });
  };

  const getAllActiveQuestionsByGroup = () => {
    if (activeQuestionGroup != null) {
      QuestionsService.getAllAMLQuestionsByGrope(activeQuestionGroup.id).then(
        (data) => {
          setAllActiveQuestionsList(data.data);
        }
      ).catch(() => {
      });
    }
  };

  const mapToClientQuestionnairesDTO = (amlCreationRequests: any[]): ClientQuestionnairesDTO[] => {
    return amlCreationRequests.map((request: any) => ({
      amlQuestionDTO: request.amlQuestionDTO,
      amlQuestionFileDtos: request.amlQuestionFileDtoList,
      nomenclatureEntryDtoList: request.nomenclatureEntryDtoList,
      questionnaire: request.questionnaire
    }));
  };

  useEffect(() => {
    const transformedData = mapToClientQuestionnairesDTO(changedFields.amlCreationRequests);
    getAllQuestionType();
    getActiveAMLQuestionsByGroup();
    setAllQuestionsList(transformedData);
  }, [changedFields]);

  useEffect(() => {
    getAllActiveQuestionsByGroup();
  }, [activeQuestionGroup]);

  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes From</Typography>
            {allActiveQuestionsList.length > 0 ? (
              allActiveQuestionsList.map((question) => {
                const labelId = `checkbox-list-label-${question.amlQuestionDTO.id}`;
                return (
                  <AMLQuestion questionTypesList={questionTypesList}
                               isCopy={false}
                               canEdit={false}
                               question={question}
                               isChangeRequest={true}
                               key={question.amlQuestionDTO.id}/>
                );
              })
            ) : (
              <Box display={'flex'} justifyContent={'center'} color={'grey'}> <i>{t('EMPTY_QUESTION_LIST')} </i></Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes To</Typography>
            {allQuestionsList.length > 0 ? (
              allQuestionsList.map((question) => {
                const labelId = `checkbox-list-label-${question.amlQuestionDTO.id}`;
                return (
                  <AMLQuestion questionTypesList={questionTypesList}
                               isCopy={false}
                               canEdit={false}
                               question={question}
                               isChangeRequest={true}
                               key={question.amlQuestionDTO.id}/>
                );
              })
            ) : (
              <Box display={'flex'} justifyContent={'center'} color={'grey'}> <i>{t('EMPTY_QUESTION_LIST')} </i></Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpdateQuestionnaireChangeRequestDetails;