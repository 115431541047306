import { SxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';

const UpdatePersonRiskChangeRequestDetails = ({
  changeRequestDetails,
  changedFields
}: { changeRequestDetails: ChangeRequestDetailsResponse | undefined; changedFields: any }) => {
  const { t } = useTranslation();

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)'
  };

  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes From</Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('FIRST_NAME')}: {changeRequestDetails?.object && 'firstName' in changeRequestDetails.object ? changeRequestDetails.object.firstName : ''}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('LAST_NAME')}: {changeRequestDetails?.object && 'lastName' in changeRequestDetails.object ? changeRequestDetails.object.lastName : ''}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('PHONE_NUMBER')}: {changeRequestDetails?.object && 'phoneNumber' in changeRequestDetails.object ? changeRequestDetails.object.phoneNumber : ''}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('BIRTH_DATE')}: {changeRequestDetails?.object && 'birthDate' in changeRequestDetails.object ? DateTimeUtils.formatDate(changeRequestDetails.object.birthDate) : ''}
            </Typography>
            <Typography variant='body1'>
              {t('ADDRESS')}: {changeRequestDetails?.object && 'address' in changeRequestDetails.object ? changeRequestDetails.object.address : ''}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes To</Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('FIRST_NAME')}: {changedFields.firstName !== undefined ? changedFields.firstName : (changeRequestDetails?.object && 'firstName' in changeRequestDetails.object ? changeRequestDetails.object.firstName : '')}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('LAST_NAME')}: {changedFields.lastName !== undefined ? changedFields.lastName : (changeRequestDetails?.object && 'lastName' in changeRequestDetails.object ? changeRequestDetails.object.lastName : '')}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('PHONE_NUMBER')}: {changedFields.phoneNumber !== undefined ? changedFields.phoneNumber : (changeRequestDetails?.object && 'phoneNumber' in changeRequestDetails.object ? changeRequestDetails.object.phoneNumber : '')}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('BIRTH_DATE')}: {changedFields.birthDate !== undefined ? DateTimeUtils.formatDate(changedFields.birthDate) : (changeRequestDetails?.object && 'birthDate' in changeRequestDetails.object ? DateTimeUtils.formatDate(changeRequestDetails.object.birthDate) : '')}
            </Typography>
            <Typography variant='body1'>
              {t('ADDRESS')}: {changedFields.address !== undefined ? changedFields.address : (changeRequestDetails?.object && 'address' in changeRequestDetails.object ? changeRequestDetails.object.address : '')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpdatePersonRiskChangeRequestDetails;
