import { CheckBox } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageLoadingCircle } from '../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { InviteClientRequest } from '../../../models/clients/InviteClientRequest';
import { ProcedureTypeShortName } from '../../../models/procedure/ProcedureTypeShortName';
import { ClientService } from '../../../repository/ClientService';
import { ProcedureService } from '../../../repository/ProcedureService';
import { BootstrapDialog, Transition } from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import { InviteClientModalProps } from './InviteClientModalProps';

const InviteClientModal = ({
  handleCloseModal, isShowModal, listAllClients, handleShowProcedureDetailsLinkModal, setSelectedClientForDetails,
  clientsList
}: InviteClientModalProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<InviteClientRequest>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    autoGenerateAml: false
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState(false);
  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.name === 'email') {
      if (e.target.validity.valid) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  };
  const onHandleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  const handleSubmitInviteClient = (e: React.FormEvent) => {
    e.preventDefault();
    inviteNewClient(formData);
  };

  const inviteNewClient = (clientData: InviteClientRequest) => {
    ClientService.existsByEmail(clientData.email).then((res) => {
      if (!res) {
        setLoading(true);
        ClientService.InviteNewClient(clientData, ProcedureTypeShortName.KYC).then((token: string) => {
          // ClientService.getByEmail(clientData.email).then((res) => {
          //   setSelectedClientForDetails(res);
          // });
          handleCloseModal();
          // ProcedureService.sendEmailToClientForProcedureToken(clientData?.email!, ProcedureTypeShortName.KYC).then(() => {});
          setLoading(false);
          SwalAlert.successfulAlert(t('SUCCESS'), t('CLIENT_SUCCESSFULLY_ADDED'));
          // handleShowProcedureDetailsLinkModal();
        })
          .catch(() => {
            setLoading(false);
            SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
          });
      } else {
        handleCloseModal();
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('CLIENT_ALREADY_EXISTS'));
      }
    });
  };

  return (
    <BootstrapDialog
      style={{ fontFamily: 'Poppins' }}
      onClose={handleCloseModal}
      aria-labelledby='customized-dialog-title'
      open={isShowModal}
      className='modalWrapper'
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth>
      <FullPageLoadingCircle loading={loading}/>
      <BootstrapDialogTitle
        id='customized-dialog-title'
        onClose={handleCloseModal}>
        {t('INVITE_NEW_CLIENT')}
      </BootstrapDialogTitle>
      <form onSubmit={handleSubmitInviteClient}>
        <DialogContent dividers>
          <Grid container spacing={4} padding={2}>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <TextField
                id='first-name-input'
                name={'firstName'}
                fullWidth={true}
                onChange={onHandleChange}
                required={true}
                label={t('FIRST_NAME')}
                variant='standard'/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <TextField
                id='last-name-input'
                fullWidth={true}
                name={'lastName'}
                onChange={onHandleChange}
                required={true}
                label={t('LAST_NAME')}
                variant='standard'/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <TextField
                id='email-input'
                fullWidth={true}
                name={'email'}
                required={true}
                type={'email'}
                error={emailError}
                helperText={emailError && t('EMAIL_CORRECT_FORMAT')}
                inputProps={{
                  type: 'email'
                }}
                onChange={onHandleChange}
                label={t('EMAIL')}
                variant='standard'/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <TextField
                id='number-input'
                fullWidth={true}
                required={true}
                name={'phoneNumber'}
                type='number'
                onChange={onHandleChange}
                label={t('PHONE_NUMBER')}
                variant='standard'/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <FormControlLabel control={<Checkbox name={'autoGenerateAml'} checked={formData.autoGenerateAml} onChange={onHandleChangeCheckbox}/>}
                                label={t('AUTO_GENERATE_AML')}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='inherit'
            className={'modalCloseButton baseFontFamily'}
            onClick={handleCloseModal}>
            {t('CLOSE')}
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            {t('INVITE')}
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>);
};
export default InviteClientModal;